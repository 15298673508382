// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  name: 'Walden', 
  assetBaseUrl:'https://genaichatbotdev.blob.core.windows.net/chatbots/student/assets/',
  authService: 'microsoft.com',
  tenantId:'7e53ec4a-d325-4228-9e0e-a55a6b8892d5',
  oktaAuthService:'oidc.okta',
  clientId:'0oawgd4xtr6pp2UJP2p7',
  issuer:'https://atge.okta.com',
  redirectUri:'https://genai.waldenu.edu/login/callback',
  oktaSignoutRedirectUri: 'https://genai.waldenu.edu',
  studentAzureChatBotServiceFlag:true,
  studentGCPChatBotServiceFlag:true,
  studentAvaamoChatBotServiceFlag:true,
  chatbotName:'Charlotte',

  studentAsStaffConfig: {
    chatUrl: 'https://prod-charlotte-microapp.waldenu.edu/assets/js/chatbot.js',
    channelId: 'eb96e0a4-d53d-478d-b7ba-8d39ebe5fe7e',
    botApiUrl: "https://bis-api-dot-app-studentportal-prod.uk.r.appspot.com/staffassistant/token",
    sasCookies : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/create_user_delegation_sas_container/",
    sasToken: "https://genai-copilot-mmp-dev.azurewebsites.net/create_user_delegation_sas_container/",
    refreshChat: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/clear_history_api/",
    searchApiRequest: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/search_api_request/",
    chatBotId: "",
    convoAction: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/convo_action/",
    convoFeedback: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/convo_feedback/",
    getChatbotId: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/get_chatbot_id/",
    citationPayloadLink : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/citation_category_group_api/",
    chatHistory : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/get_chat_history_api/",
  },
  enrollmentConfig: {
    sasCookies : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/create_user_delegation_sas_container/",
    refreshChat : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/clear_history_api/",
    searchApiRequest : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/search_api_request/",
    citationPDF : "https://genaicopilotmmpqablob.blob.core.windows.net/enrollment-copilot-qa",
    citationPayloadLink : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/citation_category_group_api/",
    convoActionAPI : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/convo_action/",
    convoFeedbackAPI: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/convo_feedback/",
    chatHistory : "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/get_chat_history_api/",
    chatBotId: "",
    getChatbotId: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/get_chatbot_id/",
    salesforceConfigUrl: "https://laureateone.lightning.force.com",
    acknowledgementAPI: "https://genai-copilot-mmp-prod-webapp.azurewebsites.net/acknowledgement_api/"
  },

  studentChatbotConfig: {
    chatUrl: 'https://prod-charlotte-microapp.waldenu.edu/assets/js/chatbot.js',
    channelId: 'eb96e0a4-d53d-478d-b7ba-8d39ebe5fe7e',
    botApiUrl: "https://bis-api-dot-app-studentportal-prod.uk.r.appspot.com/staffassistant/token"
  },

    Question1:"What support services are available for Walden students?",
    Question2:"How is Walden's faculty different from other online universities?",
    Question3:"Can you tell me about Walden's accreditation?"
};


